import React from "react";
import {
  SEO,
  PageLayout,
  AnimatedSelector,
  BackgroundImages,
  MarkdownContent,
  FadeReveal,
  ContactForm,
  Content,
  Map,
  Location,
  HoursOfOperation,
} from "@bluefin/components";
import { Header, Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class ContactPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          title={fishermanBusinessWebsitePage.seoTitle}
        />
        <PageLayout className={"contact-page"} hero={null}>
          <AnimatedSelector
            targetSelector={
              ".image.background::before, .image.background::after"
            }
            duration={1500}
            transitionType={"zoom"}
            transitionProps={{
              animating: { scale: 1 },
              preAnimating: { scale: 1.4 },
              serverSide: { scale: 1.4 },
            }}
            easing={"cubic-bezier(0,1,0.99,1)"}
            runPolicy={"RUN_ONCE_EVERY_VISIT"}
            animationKey={"team-hero-animation"}
          >
            <BackgroundImages
              images={getComponentFiles({
                components: fishermanBusinessWebsitePage.components,
                componentName: "HeroElement",
              })}
              parentClassName={"hero-container"}
              imageClassName={"hero-image overlay"}
              carouselProps={{
                leftArrowIcon: "fancy-arrow-left",
                rightArrowIcon: "fancy-arrow-right",
              }}
            >
              <Header
                as={"h1"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "contact_header",
                  defaultValue: "Contact",
                })}
              />
              <MarkdownContent
                className={"contact-description"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "contact_description",
                })}
              />
              <div className={"ctas-container"} />
            </BackgroundImages>
          </AnimatedSelector>
          <Grid
            stackable={true}
            textAlign={"center"}
            className={"contact-info-container"}
          >
            <Grid.Column width={8} className={"form-column"}>
              <FadeReveal duration={1250} triggerOnce={true}>
                <ContactForm
                  buttonText={"Submit"}
                  centeredHeaders={false}
                  hideOptIn={false}
                  className={""}
                  fluidButton={false}
                  header={
                    <div className={"contact-form-header"}>
                      <Content>
                        <Content.Markup
                          isMarkdown={true}
                          textAlign={"center"}
                          content={getComponentContentNodeContent({
                            components: fishermanBusinessWebsitePage.components,
                            componentId: "contact_form_description",
                          })}
                        />
                      </Content>
                    </div>
                  }
                  inline={false}
                  showLabels={true}
                  subheader={""}
                  termsCopy={""}
                  withContainer={false}
                  locationId={fishermanBusiness.primaryLocation._id}
                  businessId={fishermanBusiness._id}
                  businessType={fishermanBusiness.type}
                  event={{ category: "Contact", action: "Submit Inquiry" }}
                />
              </FadeReveal>
            </Grid.Column>
            <Grid.Column width={8} className={"info-column"}>
              <FadeReveal duration={1250} triggerOnce={true} delay={450}>
                <div className={"map-section"}>
                  <Map
                    businessName={fishermanBusiness.name}
                    address={{
                      street: fishermanBusiness.primaryLocation.street,
                      city: fishermanBusiness.primaryLocation.city,
                      state: fishermanBusiness.primaryLocation.state,
                      zip: fishermanBusiness.primaryLocation.zipCode,
                    }}
                  />
                </div>
                <div className={"info-section"}>
                  <div>
                    <Location
                      phone={fishermanBusiness.primaryLocation.phoneNumber}
                      email={fishermanBusiness.primaryLocation.email}
                      address={{
                        street: fishermanBusiness.primaryLocation.street,
                        city: fishermanBusiness.primaryLocation.city,
                        state: fishermanBusiness.primaryLocation.state,
                        zip: fishermanBusiness.primaryLocation.zipCode,
                      }}
                      businessName={fishermanBusiness.name}
                      showBusinessName={false}
                    />
                    <HoursOfOperation
                      hours={fishermanBusiness.primaryLocation.hours}
                      displayOption={"grouped"}
                      enhancedLabels={{
                        openingSoon: "Opening soon",
                        open: "Open now",
                      }}
                    />
                  </div>
                </div>
              </FadeReveal>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        _id
        street
        city
        state
        zipCode
        phoneNumber
        email
        hours {
          day
          open
          close
          label
        }
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Contact" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
      seoTitle
    }
  }
`;
